import { Component, OnInit, ElementRef, NgZone, ViewChild  } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { fuseAnimations } from '@fuse/animations';

/*
*
* Author: Saboor Salaam
*
*/

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  animations : fuseAnimations

})

//Page to register a new user
export class SignupComponent implements OnInit {

    public config: PerfectScrollbarConfigInterface = {};

    //Constants i.e. industry, state, country etc.
    public constants;

  hasConstants = false;


 //data model for instructor input
    public instructor_data = {
  	username:"",
  	password:"",
  	first_name:"",
        last_name:"",
  	address:'',
  	city:"",
  	state:"",
        zip_code:"",
        country:"",
  	weblink:"",
 	industry:"",
    skill_level_id:0,
      prefered_skill_level_id:0,
      gender:''
    };

  //data model for instructor input
  public student_data = {
    username:"",
    password:"",
    first_name:"",
    last_name:"",
    skill_level_id:0,
    industry_id:0,
    gender:'',
    prefered_gender:''
  };

    //data model for company input
    public company_data = {
  	username:"",
  	password:"",
  	company_name:"",
  	address:'',
  	city:"",
  	state:"",
        zip_code:"",
        country:"",
  	weblink:"",
 	industry:"",
  	company_size:25
    };

    //data model for user input
    public user_data = {
  	username:"",
  	password:"",
  	first_name:"",
        last_name:"",
  	address:"",
  	city:"",
  	state:"",
        zip_code:"",
        country:"",
  	product_key:"",
        occupation:"",
  	telephone:"",
        company_result:"",
        company_id:999
    };

    public signUpButtonText = "Sign Up";

    //Last lenght of product key input
    public last_length = 0;


    public hasCompanyError: boolean = false;
    public companyErrorText = "Error creating account";
    public hasUserError: boolean = false;
    public userErrorText = "Error creating account";
     public hasInstructorError: boolean = false;
    public instructorErrorText = "Error creating account";
  public hasStudentError: boolean = false;
  public studentErrorText = "Error creating account";



    public showLocationModel: boolean = false;
    public search_string: string = "";
    public latitude: number = null;
    public longitude: number = null;
    public address: string = null;
    public searchControl: FormControl;


  @ViewChild("search")
  public searchElementRef: ElementRef;

    @ViewChild("search2")
  public searchElementRef2: ElementRef;


  constructor(public authService: AuthService, private http: HttpClient, private ngZone: NgZone, private router: Router) {

    //Get value of all constants for 'drop down' inputs
    this.http.get('https://scribar.herokuapp.com/api/profile/get/all/constants', this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {
                                        console.log("Getting all constants...");
                                        this.constants = data;
                                        console.log(this.constants);
                                        if('industry_types' in this.constants) this.hasConstants = true;
                                    },
                                    (error) => {

                                        console.log(error);

                                        });

    }

   ngOnInit() {

    //set google maps defaults

    //create search FormControl
    this.searchControl = new FormControl();

  }

  //Registed new company
  signUpCompany(){

        this.signUpButtonText = "Loading...";

        //Send company data to server post request
         this.http.post('https://scribar.herokuapp.com/api/account/sign-up/company', this.company_data, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {

                                        //If success show prompt and reroute to login
                                        console.log(data);
                                        this.signUpButtonText = "Sign Up";
                                        this.router.navigate(['login',  true, "SIGN_UP_COMPANY"]);
                                    },
                                    (error) => {



                                        console.log(error);
                                        this.signUpButtonText = "Sign Up";
                                        this.hasCompanyError = true;

                                        //If 409 error show prompt to user else show general error prompt
                                        if(error.status === 409){
                                        this.companyErrorText = "An account with this email already exists!";
                                        }else{
                                        this.companyErrorText = "Error creating account."
                                        }

                                        });

  }

//Register user
 signUpUser(){

        this.signUpButtonText = "Loading...";

         //Send user data to server in post request
         this.http.post('https://scribar.herokuapp.com/api/account/sign-up/user', this.user_data, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {
                                       console.log(this.user_data.address);
                                        console.log(data);

                                        //if success show prompt and navigate back to login
                                        this.signUpButtonText = "Sign Up";
                                        this.router.navigate(['login',  true, "SIGN_UP_EMPLOYEE"]);

                                    },
                                    (error) => {

                                        console.log(error);
                                        this.signUpButtonText = "Sign Up";
                                        this.hasUserError = true;

                                        //if 409 error show 'email exists' message else show general message
                                        if(error.status === 409){
                                        this.userErrorText = "An account with this email already exists!";
                                        }else{
                                        this.userErrorText = "Error creating account."
                                        }

                                        });

  }

  //Register user
  signUpStudent(){

    this.signUpButtonText = "Loading...";

    //Send user data to server in post request
    this.http.post('https://scribar.herokuapp.com/api/account/sign-up/student', this.student_data, this.authService.httpOptions)
      .subscribe(
        (data) => {
          console.log(this.instructor_data.address);
          console.log(data);

          //if success show prompt and navigate back to login
          this.signUpButtonText = "Sign Up";
          this.router.navigate(['login',  true, "SIGN_UP_EMPLOYEE"]);

        },
        (error) => {

          console.log(error);
          this.signUpButtonText = "Sign Up";
          this.hasStudentError = true;

          //if 409 error show 'email exists' message else show general message
          if(error.status === 409){
            this.studentErrorText = "An account with this email already exists!";
          }else{
            this.studentErrorText = "Error creating account."
          }

        });

  }


  //Register user
 signUpInstructor(){

        this.signUpButtonText = "Loading...";

         //Send user data to server in post request
         this.http.post('https://scribar.herokuapp.com/api/account/sign-up/instructor', this.instructor_data, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {
                                       console.log(this.instructor_data.address);
                                        console.log(data);

                                        //if success show prompt and navigate back to login
                                        this.signUpButtonText = "Sign Up";
                                        this.router.navigate(['login',  true, "SIGN_UP_EMPLOYEE"]);

                                    },
                                    (error) => {

                                        console.log(error);
                                        this.signUpButtonText = "Sign Up";
                                        this.hasInstructorError = true;

                                        //if 409 error show 'email exists' message else show general message
                                        if(error.status === 409){
                                        this.instructorErrorText = "An account with this email already exists!";
                                        }else{
                                        this.instructorErrorText = "Error creating account."
                                        }

                                        });

  }

 //Search for company product key
 searchProductKey(){

    //If product key is atleast 18 characters long
    if(this.user_data.product_key.length >= 18 ){

             this.last_length = this.user_data.product_key.length;


             //Retrieve company data for product key from server
              this.http.get('https://scribar.herokuapp.com/api/account/search/company?key=' + this.user_data.product_key, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {
                                        this.user_data.company_result = data['company_name'];
                                        this.user_data.company_id = data['company_id'];
                                        console.log(data);
                                        console.log(this.user_data);
                                        },
                                    (error) => {

                                        console.log(error);
                                        this.user_data.company_result = "Company not found";


                                        });


        }

  }




}
