import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';

import { fuseConfig } from './fuse-config';

import { AppComponent } from './app.component';
import { FuseDirectivesModule } from '@fuse/directives/directives';


import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';

//Angular Google Maps
import {MatSnackBarModule} from '@angular/material/snack-bar';


import { ChartsModule } from 'ng2-charts';


//Angular Material
import {MatRadioModule} from '@angular/material/radio';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatBadgeModule} from '@angular/material/badge';
import {MatChipsModule} from '@angular/material/chips';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatStepperModule} from '@angular/material/stepper';
import {MatGridListModule} from '@angular/material/grid-list';


import { AuthService } from './services/auth.service';
import { AuthGuardService } from './services/auth-guard.service';


import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';

import { AceEditorModule } from 'ng2-ace-editor';


import { CompanyViewAccountComponent } from './dashboard/company/company-view-account/company-view-account.component';
import { CompanyEditAccountComponent } from './dashboard/company/company-edit-account/company-edit-account.component';
import { UserEditAccountComponent } from './dashboard/user/user-edit-account/user-edit-account.component';
import { UserViewAccountComponent } from './dashboard/user/user-view-account/user-view-account.component';

import { ViewRequestsComponent } from './dashboard/company/view-requests/view-requests.component';
import { WorflowPipePipe } from './pipes/worflow-pipe.pipe';
import { AnyPipePipe } from './pipes/any-pipe.pipe';


import { LoginComponent } from './auth/login/login.component';
import { SignupComponent } from './auth/signup/signup.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ConfirmResetPasswordComponent } from './auth/confirm-reset-password/confirm-reset-password.component';
import { CompanyDashboardComponent } from './dashboard/company/company-dashboard/company-dashboard.component';
import { CompanyDashboardRequestsComponent } from './dashboard/company/company-dashboard-requests/company-dashboard-requests.component';
import { CompanyDashboardManageEmployeesComponent } from './dashboard/company/company-dashboard-manage-employees/company-dashboard-manage-employees.component';
import { CompanyDashboardWorkflowsComponent } from './dashboard/company/company-dashboard-workflows/company-dashboard-workflows.component';
import { UserDashboardComponent } from './dashboard/user/user-dashboard/user-dashboard.component';
import { CompanyDashboardEditWorkflowComponent } from './dashboard/company/company-dashboard-edit-workflow/company-dashboard-edit-workflow.component';
import { CompanyDashboardViewWorkflowComponent } from './dashboard/company/company-dashboard-view-workflow/company-dashboard-view-workflow.component';
import { CompanyDashboardCreateWorkflowComponent } from './dashboard/company/company-dashboard-create-workflow/company-dashboard-create-workflow.component';
import { AdminDashboardComponent } from './dashboard/admin/admin-dashboard/admin-dashboard.component';
import { CompanyDashboardViewTaskComponent } from './dashboard/company/company-dashboard-view-task/company-dashboard-view-task.component';
import { CompanyDashboardEditTaskComponent } from './dashboard/company/company-dashboard-edit-task/company-dashboard-edit-task.component';
import { CompanyDashboardCreateTaskComponent } from './dashboard/company/company-dashboard-create-task/company-dashboard-create-task.component';
import { CompanyDashboardGroupsComponent } from './dashboard/company/company-dashboard-groups/company-dashboard-groups.component';
import { CompanyDashboardCreateGroupComponent } from './dashboard/company/company-dashboard-create-group/company-dashboard-create-group.component';
import { CompanyDashboardEditGroupComponent } from './dashboard/company/company-dashboard-edit-group/company-dashboard-edit-group.component';
import { CompanyDashboardViewGroupComponent } from './dashboard/company/company-dashboard-view-group/company-dashboard-view-group.component';
import { CompanyDashboardViewProfileComponent } from './dashboard/company/company-dashboard-view-profile/company-dashboard-view-profile.component';
import { CompanyDashboardEditProfileComponent } from './dashboard/company/company-dashboard-edit-profile/company-dashboard-edit-profile.component';
import { CompanyDashboardCreateProfileComponent } from './dashboard/company/company-dashboard-create-profile/company-dashboard-create-profile.component';
import { CompanyDashboardCreateProfileSelfComponent } from './dashboard/company/company-dashboard-create-profile-self/company-dashboard-create-profile-self.component';

import { CompanyDashboardAddPaymentInfoComponent } from './dashboard/company/company-dashboard-add-payment-info/company-dashboard-add-payment-info.component';
import { CompanyDashboardViewDevicesComponent } from './dashboard/company/company-dashboard-view-devices/company-dashboard-view-devices.component';

import { ConfirmDialog } from './dashboard/company/confirm-dialog/confirm.component';
import { ConditionsDialog } from './dashboard/company/conditions-dialog/conditions.component';
import { RoutesDialog } from './dashboard/company/routes-dialog/routes.component';

import { HelpDialog } from './dashboard/company/help-dialog/help.component';


import {VgCoreModule} from 'videogular2/core';
import {VgControlsModule} from 'videogular2/controls';
import {VgOverlayPlayModule} from 'videogular2/overlay-play';
import {VgBufferingModule} from 'videogular2/buffering';
import { VgStreamingModule } from 'videogular2/streaming';
import { CompanyDashboardViewPerformanceRecordsComponent } from './dashboard/company/company-dashboard-view-performance-records/company-dashboard-view-performance-records.component';
import { CompanyDashboardViewPerformanceRecordComponent } from './dashboard/company/company-dashboard-view-performance-record/company-dashboard-view-performance-record.component';
import { CompanyDashboardViewStepPerformanceDataComponent } from './dashboard/company/company-dashboard-view-step-performance-data/company-dashboard-view-step-performance-data.component';

import { LocalStorageModule } from 'angular-2-local-storage';
import {DndModule} from 'ng2-dnd';

import { ToolbarComponent } from './toolbar/toolbar.component';

import { EditorModule } from '@tinymce/tinymce-angular';
import { NgxEditorModule } from 'ngx-editor';

import { CompanyViewTaskPerformanceRecordComponent } from './dashboard/company/company-view-task-performance-record/company-view-task-performance-record.component';
import { CompanyDashboardAddOverlayComponent } from './dashboard/company/company-dashboard-add-overlay/company-dashboard-add-overlay.component';
import { InstructorDashboardMainComponent } from './dashboard/instructor/instructor-dashboard-main/instructor-dashboard-main.component';
import { InstructorDashboardLiveComponent } from './dashboard/instructor/instructor-dashboard-live/instructor-dashboard-live.component';
import { CompanyDashboardSelectPlanComponent } from './dashboard/company/company-dashboard-select-plan/company-dashboard-select-plan.component';
import { CompanyDashboardEditPhotoComponent } from './dashboard/company/company-dashboard-edit-photo/company-dashboard-edit-photo.component';
import { ReportComponent } from './dashboard/traffic_stop/report/report.component';



const appRoutes: Routes = [
    {
        path      : '**',
        redirectTo: 'sample'
    }
];

@NgModule({
    declarations: [
    AppComponent,
    ToolbarComponent,
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ConfirmResetPasswordComponent,
    CompanyDashboardComponent,
    CompanyDashboardRequestsComponent,
    CompanyDashboardManageEmployeesComponent,
    CompanyDashboardWorkflowsComponent,
    UserDashboardComponent,
    CompanyDashboardEditWorkflowComponent,
    CompanyDashboardViewWorkflowComponent,
    CompanyDashboardCreateWorkflowComponent,
    AdminDashboardComponent,
    CompanyDashboardViewTaskComponent,
    CompanyDashboardEditTaskComponent,
    CompanyDashboardCreateTaskComponent,
    CompanyDashboardGroupsComponent,
    CompanyDashboardCreateGroupComponent,
    CompanyDashboardEditGroupComponent,
    CompanyDashboardViewGroupComponent,
    CompanyDashboardViewProfileComponent,
    CompanyDashboardEditProfileComponent,
    CompanyDashboardCreateProfileComponent,
    CompanyDashboardAddPaymentInfoComponent,
    CompanyDashboardViewDevicesComponent,
    CompanyDashboardViewPerformanceRecordsComponent,
    CompanyDashboardViewPerformanceRecordComponent,
    CompanyDashboardViewStepPerformanceDataComponent,
    CompanyDashboardCreateProfileSelfComponent,
    ConfirmDialog,
    ConditionsDialog,
    RoutesDialog,
    HelpDialog,
    ViewRequestsComponent,
    WorflowPipePipe,
    AnyPipePipe,
    CompanyViewAccountComponent,
    CompanyEditAccountComponent,
    UserEditAccountComponent,
    UserViewAccountComponent,
    CompanyViewTaskPerformanceRecordComponent,
    CompanyDashboardAddOverlayComponent,
    InstructorDashboardMainComponent,
    InstructorDashboardLiveComponent,
    CompanyDashboardSelectPlanComponent,
    CompanyDashboardEditPhotoComponent,
    ReportComponent
    ],
    imports     : [
        BrowserModule,
        NgxEditorModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot(),
        AppRoutingModule,
        FormsModule,
        EditorModule,
        MatProgressBarModule, MatGridListModule, MatStepperModule,MatSnackBarModule, MatButtonToggleModule, MatSlideToggleModule, MatDialogModule, MatChipsModule, MatRadioModule, MatBadgeModule, MatPaginatorModule, MatTableModule, MatTabsModule,MatIconModule, MatSelectModule, MatTooltipModule, MatProgressSpinnerModule, MatToolbarModule,MatCheckboxModule, MatExpansionModule, MatCardModule, MatDatepickerModule, MatIconModule, MatButtonModule, MatMenuModule, MatInputModule, MatProgressSpinnerModule,MatSidenavModule,MatListModule,
        MatChipsModule,
        ReactiveFormsModule,
        AceEditorModule,
        GalleryModule.forRoot({}),
        LightboxModule.forRoot({}),

        // Fuse Main and Shared modules
        FuseModule.forRoot(fuseConfig),
        FuseSharedModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        VgStreamingModule,
        DndModule.forRoot(),
        ChartsModule,
        LocalStorageModule.forRoot({
            prefix: 'scribar',
            storageType: 'localStorage'
        })
    ],
    entryComponents: [ConfirmDialog, HelpDialog, ConditionsDialog,RoutesDialog],
    providers: [AuthService, AuthGuardService],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
