import { Component, OnInit, ElementRef, NgZone, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';

/*
*
* Author: Saboor Salaam
*
*/

@Component({
  selector: 'app-company-edit-account',
  templateUrl: './company-edit-account.component.html',
  styleUrls: ['./company-edit-account.component.css']
})
export class CompanyEditAccountComponent implements OnInit {

//Constants i.e. industry, state, country etc.
public constants;

company_data = {
  	username:"",
  	password:"",
  	company_name:"",
  	address:'',
  	city:"",
  	state:"",
        zip_code:"",
        country:"",
  	weblink:"",
 	industry:"",
  	company_size:25,
        new_password:"",
        old_password:""
    };

    public saveAccountButtonText = "Update account";
    public hasSaveAccountError: boolean = false;
    public accountErrorText = "Error updating account";
    public hasAccountSuccess = false;

     public savePasswordButtonText = "Update password";
    public hasSavePasswordError: boolean = false;
    public passwordErrorText = "Error updating password";
    public hasPasswordSuccess = false;

    public latitude: number = null;
    public longitude: number = null;
    public address: string = null;
    public searchControl: FormControl;


    @ViewChild("search")
  public searchElementRef: ElementRef;

    @ViewChild("search2")
  public searchElementRef2: ElementRef;


    constructor(public authService: AuthService, private http: HttpClient, private router: Router, private route: ActivatedRoute,
    private ngZone: NgZone) {

    this.company_data = this.authService.userProfile['c'];

    //Get value of constants
    this.http.get('https://scribar.herokuapp.com/api/profile/get/all/constants', this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {
                                        console.log("Getting all constants...");
                                        this.constants = data;
                                        console.log(this.constants);
                                    },
                                    (error) => {

                                        console.log(error);

                                        });

    }

    ngOnInit() {
    //set google maps defaults
    //this.zoom = 4;
    //this.latitude = 39.8282;
    //this.longitude = -98.5795;

    //create search FormControl
    this.searchControl = new FormControl();

  }

  saveAccount(){

        this.saveAccountButtonText = "Saving...";

         this.http.post('https://scribar.herokuapp.com/api/dashboard/update/company/account', this.company_data, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {
                                        console.log(data);
                                        this.saveAccountButtonText = "Update account";
                                        this.hasAccountSuccess = true;
                                        //this.router.navigate(['/d/organization/account']);

                                    },
                                    (error) => {



                                        });

  }

  resetPassword(){

         this.savePasswordButtonText = "Saving...";

         this.http.post('https://scribar.herokuapp.com/api/dashboard/update/password',
{
  "old_password":this.company_data.old_password,
  "new_password":this.company_data.new_password
}, this.authService.httpOptions)
                                    .subscribe(
                                    (data) => {
                                        console.log(data);
                                        this.savePasswordButtonText = "Update password";
                                        this.hasPasswordSuccess = true;
                                        //this.router.navigate(['/d/organization/account']);

                                    },
                                    (error) => {

                                        console.log(error);
                                        this.savePasswordButtonText = "Update password";
                                        this.hasSavePasswordError = true;
                                        if(error.status === 401){
                                        this.passwordErrorText = "Exisitng password is incorrect try again.";
                                        }else{
                                        //this.errorText = "Error updating password."
                                        }

                                        });

  }


}

